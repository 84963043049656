import React, { Component } from "react";

import SessionItem from "../SessionItem";
import SessionList from "../SessionList";
import DefaultScreen from "../DefaultScreen";

import { StyledTodaysSession } from "./styles";
import SessionItemLandscape from "../SessionItemLandscape";

export default class TodaySession extends Component {
  constructor(props) {
    super(props);
    this.todaySessionRef = React.createRef();
    this.isComponentMounted = true;
  }

  state = {
    pointPosition: 0,
  };

  componentDidMount() {
    const pointRect = this.todaySessionRef.current.getBoundingClientRect().bottom;
    this.setState({
      pointPosition: pointRect,
    });
    this.isComponentMounted = true;
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    const pointRect = this.todaySessionRef.current.getBoundingClientRect().bottom;

    if (prevState.pointPosition !== pointRect) {
      return pointRect;
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        pointPosition: snapshot,
      });
    }

    if (prevProps.currentViewContent === "programme" && this.props.currentViewContent !== "programme") {
      this.isComponentMounted = false;
    }
  }

  defineProgrammeStyles = (bodyHeightPercent, nowSessions, nextSessions, insidePlaylist, isProgrammeTheCurrentContent, isFullScreen, isSession) => {
    const { isInsidePlBigger60 } = this.props;

    const insidePlaylistPercent = (insidePlaylist * 100) / bodyHeightPercent;

    const calculateRemainder = 100 - insidePlaylistPercent;
    const onePart = calculateRemainder / 2;

    if (!isSession || isFullScreen) {
      return { gridTemplateRows: "100%" };
    }
    const hasNowAndNext = nowSessions.length && nextSessions.length;
    const length = nowSessions.length + nextSessions.length;
    const hasNowOrNext = (nowSessions.length && !nextSessions.length) || (!nowSessions.length && nextSessions.length);

    if (isInsidePlBigger60 && !isProgrammeTheCurrentContent) {
      const gridTemplateRows = `${calculateRemainder}% ${insidePlaylistPercent}%`;
      return { gridTemplateRows };
    }

    if (hasNowAndNext) {
      const gridTemplateRows = isProgrammeTheCurrentContent ? `${Array(length).fill("min-content").join(" ")} auto` : `auto auto ${insidePlaylistPercent}%`;
      return { gridTemplateRows };
    }

    if (hasNowOrNext) {
      const gridTemplateRows = isProgrammeTheCurrentContent ? `${Array(length).fill("min-content").join(" ")} auto` : `${length === 1 ? "auto" : "auto auto"} ${insidePlaylistPercent}%`;
      return { gridTemplateRows };
    }

    const isEmptyNowAndNext = !hasNowOrNext && !hasNowAndNext;
    if (isEmptyNowAndNext) {
      return { gridTemplateRows: "100%" };
    }

    const gridTemplateRows = `${onePart}% ${onePart}% ${insidePlaylistPercent}%`;

    return { gridTemplateRows };
  };

  renderNowAndNext = ({ isProgrammeTheCurrentContent, isOneSession, hex, colorText, fontFamily, isLandscape }) => {
    const { isFullScreen } = this.props;
    if (isFullScreen) return null;

    const { nowSessions, nextSessions, isInsidePlBigger60, isInsidePlBigger54, rootFontSize, brandContent, options, currentTheatre } = this.props;

    const { pointPosition } = this.state;

    const hasNowAndNext = nowSessions.length && nextSessions.length;
    const fontScalePercentage = Number(options.fontScale) || 100;
    const descriptionLineSpacing = options.descriptionLineSpacing;
    const titleLineSpacing = options.titleLineSpacing;
    const speakersLineSpacing = options.speakersLineSpacing;
    const elementsSpacing = options.elementsSpacing;

    // ADDED .reverse() TO FIX SORTING

    const limitedNowSessions = !isProgrammeTheCurrentContent ? nowSessions.slice(0, 2) : nowSessions;
    const limitedNextSessions = !isProgrammeTheCurrentContent ? nextSessions.slice(0, Math.max(0, 2 - limitedNowSessions.length)) : nextSessions;

    const sessionItemProps = {
      brandContent,
      isInsidePlBigger60,
      isInsidePlBigger54,
      isMinimize: !isProgrammeTheCurrentContent,
      rootFontSize,
      isOneSession,
      fontFamily,
      colorText,
      hex,
    };

    const renderNow = () =>
      limitedNowSessions.map((nowSession) => {
        return isLandscape ? <SessionItemLandscape status="now" titleLineSpacing={titleLineSpacing} descriptionLineSpacing={descriptionLineSpacing} speakersLineSpacing={speakersLineSpacing} elementsSpacing={elementsSpacing} fontScalePercentage={fontScalePercentage} session={nowSession} programmes={currentTheatre} {...sessionItemProps} /> : <SessionItem status="now" titleLineSpacing={titleLineSpacing} descriptionLineSpacing={descriptionLineSpacing} speakersLineSpacing={speakersLineSpacing} elementsSpacing={elementsSpacing} fontScalePercentage={fontScalePercentage} session={nowSession} programmes={currentTheatre} pointPosition={pointPosition} {...sessionItemProps} />;
      });

    const renderNext = () =>
      limitedNextSessions.map((nextSession) => {
        return isLandscape ? <SessionItemLandscape status="next" descriptionLineSpacing={descriptionLineSpacing} titleLineSpacing={titleLineSpacing} speakersLineSpacing={speakersLineSpacing} elementsSpacing={elementsSpacing} fontScalePercentage={fontScalePercentage} session={nextSession} programmes={currentTheatre} pointPosition={pointPosition} {...sessionItemProps} /> : <SessionItem status="next" descriptionLineSpacing={descriptionLineSpacing} titleLineSpacing={titleLineSpacing} speakersLineSpacing={speakersLineSpacing} elementsSpacing={elementsSpacing} fontScalePercentage={fontScalePercentage} session={nextSession} programmes={currentTheatre} pointPosition={pointPosition} {...sessionItemProps} />;
      });

    const renderNowOrNext = () => (
      <React.Fragment>
        {nowSessions.length > 0 && renderNow()}
        {nextSessions.length > 0 && renderNext()}
      </React.Fragment>
    );

    if (isInsidePlBigger60 && !isProgrammeTheCurrentContent) {
      if (hasNowAndNext) {
        return renderNow();
      }
    }

    return renderNowOrNext();
  };

  render() {
    const {
      todaysSessions: { sessions },
      options,
      nowSessions,
      nextSessions,
      remainingSessionsToday,
      currentViewContent: { type: currentViewContentType },
      isFullScreen,
      rootFontSize,
      bodyHeightPercent,
      currentTheatre,
      activeScreen: { screenSize },
    } = this.props;

    const {
      themeColor: { hex },
      sizes: { insidePlaylist },
      fontColor: colorText,
      fontScale,
      descriptionLineSpacing,
      titleLineSpacing,
      speakersLineSpacing,
      elementsSpacing,
    } = options;

    const { pointPosition } = this.state;

    const isProgrammeTheCurrentContent = currentViewContentType === "programme";

    const isOneSession = (nowSessions.length && !nextSessions.length) || (!nowSessions.length && nextSessions.length);

    const areSessionsLeft = sessions.length || nowSessions.length || nextSessions.length;
    const fontFamily = options.fontFamily || "Open Sans";
    const fontScalePercentage = Number(fontScale) || 100;
    const isLandscape = screenSize.name === "Landscape";

    return (
      <StyledTodaysSession id="todaysSessions" fontScalePercentage={fontScalePercentage} titleLineSpacing={titleLineSpacing} descriptionLineSpacing={descriptionLineSpacing} speakersLineSpacing={speakersLineSpacing} elementsSpacing={elementsSpacing} ref={this.todaySessionRef} style={this.defineProgrammeStyles(bodyHeightPercent, nowSessions, nextSessions, insidePlaylist, isProgrammeTheCurrentContent, isFullScreen, areSessionsLeft)} fontFamily={fontFamily} hex={hex}>
        {this.renderNowAndNext({
          isProgrammeTheCurrentContent,
          isOneSession,
          hex,
          colorText,
          fontFamily,
          isLandscape,
        })}
        {isProgrammeTheCurrentContent && (
          <div className="programme">
            {!this.isComponentMounted && (
              <React.Fragment>
                <div className="closeTop" />
                <div className="middleBlock" />
                <div className="closeBottom" />
              </React.Fragment>
            )}
            <SessionList rootFontSize={rootFontSize} fontScalePercentage={fontScalePercentage} descriptionLineSpacing={descriptionLineSpacing} titleLineSpacing={titleLineSpacing} speakersLineSpacing={speakersLineSpacing} elementsSpacing={elementsSpacing} fontFamily={fontFamily} sessionList={remainingSessionsToday} areSessionsLeft={areSessionsLeft} pointPosition={pointPosition} hex={hex} nowSessions={nowSessions} nextSessions={nextSessions} programmes={currentTheatre} isLandscape={isLandscape} />
          </div>
        )}

        {/* if current day session is empty, this returns "All sessions are finished." */}
        {!areSessionsLeft && isProgrammeTheCurrentContent && (
          <div className="programme">
            <SessionList rootFontSize={rootFontSize} fontFamily={fontFamily} pointPosition={pointPosition} hex={hex} areSessionsLeft={areSessionsLeft} sessionList={remainingSessionsToday} nowSessions={nowSessions} nextSessions={nextSessions} />
          </div>
        )}

        {/* showing now and next and content underneath */}

        <div className={!isProgrammeTheCurrentContent ? "show" : "hide"}>
          <div className={!isProgrammeTheCurrentContent ? "openTop" : null} />
          <div className={!isProgrammeTheCurrentContent ? "openBottom" : null} />

          <DefaultScreen {...this.props} />
        </div>
      </StyledTodaysSession>
    );
  }
}
